<template>
  <div class="center">
    <el-container>
      <tree-layout ref="treeLayout" v-if="!isIndex && refresh" :treeLoading="treeLoading" treeTitle="组织架构" :tree-options="treeOptions" @tree-node-click="treeNodeClick" style="margin-right:5px;"></tree-layout>
      <el-main>
    <!--列表label-->
    <head-layout :head-title="this.$t('cip.plat.sync.user.title.indexHeadTitle')"
                 :head-btn-options="headBtnOptions"
                 @head-remove="handleDelete"
                 @head-add="headAdd"
                 ></head-layout>
    <!--列表查询条件和头部按钮-->
    <grid-head-layout ref="gridHeadLayout"
                      :search-columns="searchColumns"
                      @grid-head-search="gridHeadSearch"
                      @grid-head-empty="gridHeadEmpty"
    ></grid-head-layout>
    <grid-layout ref="gridLayOut"
                 :class="!isIndex?'userGridLayout':''"
                 :head-title="this.$t('cip.plat.sync.user.title.indexHeadTitle')"
                 :table-options="option"
                 :table-data="data"
                 :table-loading="loading"
                 :data-total="page.total"
                 :grid-row-btn="gridRowBtn"
                 :page="page"
                 @grid-row-detail-click="rowView"
                 @page-current-change="onLoad"
                 @page-size-change="onLoad"
                 @gird-handle-select-click="selectionChange"
                 @row-edit="rowEdit"
                 @row-del="rowDel"
                 @row-choice="rowChoice">
                 <template #expand="{data}">
                    <el-table
                      :data="data.row.sysRefs"
                      size="mini">
                      <el-table-column
                        type="index"
                        label="序号"
                        align="center"
                        width="80">
                      </el-table-column>
                      <el-table-column
                        prop="orgName"
                        label="公司"
                        align="center"
                      >
                      </el-table-column>
                      <el-table-column
                        prop="deptName"
                        label="部门"
                        align="center"
                      >
                      </el-table-column>
                      <el-table-column
                        prop="positionName"
                        label="岗位"
                        align="center"
                      >
                      </el-table-column>
                    </el-table>
                </template>
    </grid-layout>
  </el-main>
    </el-container>
  </div>
</template>

<script>
import {PAGE_CONSTANT} from "@/util/pageConstantEnum";
import {getList, remove} from "@/api/sync/user";
import {getLazyList} from "@/api/sync/dept";
import {mapGetters} from "vuex";
import HeadLayout from "@/views/components/layout/head-layout";
import GridLayout from "@/views/components/layout/grid-layout";
import TreeLayout from "@/views/components/layout/tree-layout";
export default {
  components: {HeadLayout, GridLayout,TreeLayout},
  props:{
    isIndex:{
      type:Boolean,
      default:true
    },
  },
  data() {
    return {
      treeDeptCode: '', // 左侧树点击组织架构code
      treeDeptUnity:'', // 左侧树点击组织架构unity
      refresh:true, // 左侧树结构清空时候刷新
      form: {},
      query: {},
      loading: true,
      treeLoading: true,
      page: {
        pageSize: PAGE_CONSTANT.DEFAULT_PAGE_SIZE,
        currentPage: 1,
        total: 0
      },
      selectionList: [],
      option: {
        index: true,
        selection: this.isIndex, // isIndex == true 表示是当前项目列表页面 == false 表示从黑名单组织或者黑名单人员中点击弹框进入
        indexLabel: this.$t("cip.cmn.title.SerialNumber"),
        expand:!this.isIndex,
        menuWidth: 120,
        linklabel: "title",
        column: [
          {
            label: this.$t('cip.plat.sync.user.field.account'),
            prop: "account",
            align: "left",
            overHidden: true,
            width: 240,
          },
          {
            label: this.$t('cip.plat.sync.user.field.name'),
            prop: "name",
            align: "left",
            overHidden: true,
          },
          {
            label: this.$t('cip.plat.sync.user.field.sex'),
            prop: "sex",
            type: "select",
            align: "center",
            width: 120,
            dicUrl: "/api/sinoma-system/dict-biz/dictionary?code=project_sex_type",
            props: {
              label: "dictValue",
              value: "dictKey"
            },
          },
          {
            label: this.$t('cip.plat.sync.user.field.politics'),
            prop: "politics",
            type: "select",
            align: "center",
            width: 120,
            dicUrl: "/api/sinoma-system/dict-biz/dictionary?code=politics",
            props: {
              label: "dictValue",
              value: "dictKey"
            },
          },
          {
            label: this.$t('cip.plat.sync.user.field.highLevel'),
            prop: "highLevel",
            type: "select",
            align: "center",
            width: 120,
            dicUrl: "/api/sinoma-system/dict-biz/dictionary?code=high_level",
            props: {
              label: "dictValue",
              value: "dictKey"
            },
          },
          {
            label: this.$t('cip.plat.sync.user.field.rank'),
            prop: "rank",
            align: "center",
            overHidden: true,
            hide:!this.isIndex,
            width: 120,
          },
          {
            label: this.$t('cip.plat.sync.user.field.status'),
            prop: "status",
            type: "select",
            dataType: "number",
            align: "center",
            width: 120,
            clearable: false,
            hide:!this.isIndex,
            dicUrl: "/api/sinoma-system/dict-biz/dictionary?code=ISENBLE",
            props: {
              label: "dictValue",
              value: "dictKey"
            },
          },
        ]
      },
      data: [],
    };
  },
  computed: {
    ...mapGetters(["userInfo","permission"]),
    treeOptions() {
      let that = this;
      return {
        defaultExpandAll: false,
        nodeKey: 'id',
        lazy: true,
        treeLoad: function (node, resolve) {
          that.treeLoading = true;
          const pcode = (node.level === 0) ? '' : node.data.code;
          getLazyList(1,1000,pcode,{}).then(res => {
            that.treeLoading = false;
            resolve(res.data.data.records.map(item => {
              return {
                ...item,
                leaf: !item.hasChildren
              }
            }))
          });
        },
        menu: false,
        addBtn: false,
        // size: 'mini',
        props: {
          labelText: this.$t("cip.plat.sys.user.field.title"),
          label: 'name',
          value: 'code',
          children: 'children'
        }
      }
    },
    ids() {
      let ids = [];
      this.selectionList.forEach(ele => {
        ids.push(ele.code);
      });
      return ids.join(",");
    },
    searchColumns() {
      return [
        {
          label: this.$t('cip.plat.sync.user.field.name'),
          prop: "name",
          span: 4,
          placeholder: this.$t('cip.cmn.rule.inputWarning') + this.$t('cip.plat.sync.user.field.name'),
        },
        {
          label: this.$t('cip.plat.sync.user.field.phone'),
          prop: "phone",
          span: 4,
          placeholder: this.$t('cip.cmn.rule.inputWarning') + this.$t('cip.plat.sync.user.field.phone'),
        },
        {
          label: this.$t('cip.plat.sync.user.field.politics'),
          prop: "politics",
          span: 4,
          type: "select",
          dicData: [],
          dicUrl: "/api/sinoma-system/dict-biz/dictionary?code=politics",
          placeholder: this.$t('cip.cmn.rule.selectWarning') + this.$t('cip.plat.sync.user.field.politics'),
          props: {
            label: "dictValue",
            value: "dictKey"
          },
        },
        {
          label: this.$t('cip.plat.sync.user.field.highLevel'),
          prop: "highLevel",
          span: 4,
          type: "select",
          dicData: [],
          dicUrl: "/api/sinoma-system/dict-biz/dictionary?code=high_level",
          placeholder: this.$t('cip.cmn.rule.selectWarning') + this.$t('cip.plat.sync.user.field.highLevel'),
          props: {
            label: "dictValue",
            value: "dictKey"
          },
        },
        {
          label: this.$t('cip.plat.sync.user.field.status'),
          prop: "status",
          span: 4,
          type: "select",
          dataType: "number",
          dicData: [],
          dicUrl: "/api/sinoma-system/dict-biz/dictionary?code=ISENBLE",
          placeholder: this.$t('cip.cmn.rule.selectWarning') + this.$t('cip.plat.sync.user.field.status'),
          props: {
            label: "dictValue",
            value: "dictKey"
          },
        }
      ]
    },
    headBtnOptions() {
      let buttonBtn = [];
      if(this.isIndex){
      // if (this.permission.personalSharing_add) {
      buttonBtn.push(
        {
          label: this.$t('cip.cmn.btn.addBtn'),
          emit: "head-add",
          btnOptType: 'add',
        });
      // }
      // if (this.permission.personalSharing_delete) {
      buttonBtn.push(
        {
          label: this.$t('cip.cmn.btn.delBtn'),
          emit: "head-remove",
          btnOptType: 'remove',
        });
      // }
      }
      return buttonBtn;
    },
    // 行按钮添加
    gridRowBtn() {
      let buttonBtn = [];
      // 如果是当前用户数据列表则展示编辑删除
      if(this.isIndex){
      // if (this.permission.personalSharing_edit) {
      buttonBtn.push(
        {
          label: this.$t('cip.cmn.btn.editBtn'),
          emit: "row-edit",
        });
      // }
      // if (this.permission.personalSharing_delete) {
      buttonBtn.push(
        {
          label: this.$t('cip.cmn.btn.delBtn'),
          emit: "row-del",
        })
      // }
    }else{
      // 如果是从弹框进入则展示选择按钮；传值给父组件
      buttonBtn.push(
      {
        label: this.$t('cip.cmn.btn.choice'),
        emit: "row-choice",
      })
    }
      return buttonBtn;
    },
  },
  methods: {
    // 左侧树结构点击
    treeNodeClick(node) {
      this.treeDeptCode = node.code;
      this.treeDeptUnity = node.unity
      this.page.currentPage = 1;
      this.onLoad(this.page);
    },
    // 列表查询
    gridHeadSearch(searchForm) {
      this.page.currentPage = 1;
      this.onLoad(this.page, searchForm);
    },
    // 查询重置
    gridHeadEmpty(searchForm) {
      this.page.currentPage = 1;
      this.treeDeptCode = '';
      this.treeDeptUnity = '';
      this.refresh = false;
      setTimeout(()=>{
        this.$nextTick(()=>{
          this.refresh = true
        })
      },100)
      this.onLoad(this.page, searchForm);
    },
    headAdd() {
      this.$router.push({
        path: `/system/sync/user/add`,
        query: {
          type: 'add',
        }
      })
    },
    rowEdit(row) {
      this.$router.push({
        path: `/system/sync/user/edit`,
        query: {
          id: row.id,
          type: 'edit',
        }
      })
    },
    rowView(row) {
      this.$router.push({
        path: `/system/sync/user/view`,
        query: {
          id: row.id,
          type: 'view',
        }
      })
    },
    rowDel(row) {
      this.$confirm(this.$t('cip.cmn.msg.warning.determineDelWarning'), {
        confirmButtonText: this.$t('cip.cmn.btn.defBtn'),
        cancelButtonText: this.$t('cip.cmn.btn.celBtn'),
        type: "warning"
      })
        .then(() => {
          remove(row.code).then((res) => {
            if (res.data.code == 200) {
              this.onLoad(this.page);
              this.$message({
                type: "success",
                message: this.$t("cip.cmn.msg.success.operateSuccess")
              });
            }
          })
        })
    },
    rowChoice(row){
      row.realName=row.name
      this.$emit('selectUser',row)
    },
    selectionChange(list) {
      this.selectionList = list;
    },
    handleDelete() {
      if (this.selectionList.length === 0) {
        this.$message.warning(this.$t('cip.cmn.msg.warning.chooseAtLeastOne'));
        return;
      }
      this.$confirm(this.$t('cip.cmn.msg.warning.determineDelWarning'), {
        confirmButtonText: this.$t('cip.cmn.btn.defBtn'),
        cancelButtonText: this.$t('cip.cmn.btn.celBtn'),
        type: "warning"
      })
        .then(() => {
          remove(this.ids).then((res) => {
            if (res.data.code == 200) {
              this.onLoad(this.page);
              this.$message({
                type: "success",
                message: this.$t("cip.cmn.msg.success.operateSuccess")
              });
            }
          })
        })
    },
    onLoad(page, params = {}) {
      this.page = page;
      this.loading = true;
      if(this.treeDeptUnity && this.treeDeptUnity == '部门'){
        params.deptCode = this.treeDeptCode
        params.unity = this.treeDeptUnity
      }else if(this.treeDeptUnity && this.treeDeptUnity != '部门'){
        params.orgCode = this.treeDeptCode
        params.unity = this.treeDeptUnity
      }
      getList(page.currentPage, page.pageSize, Object.assign(params, this.$refs.gridHeadLayout.searchForm)).then(res => {
        const data = res.data.data;
        this.page.total = data.total;
        this.$refs.gridLayOut.page.total = data.total;
        this.data = data.records;
        this.loading = false;
        this.selectionList = [];
      });
      this.$nextTick(() => {
        this.$refs.gridLayOut.$refs.grid.$refs.table.doLayout();
      })
    },
  },
  // 初始化数据
  mounted() {
    this.onLoad(this.page, {});
  },
};
</script>
<style scoped lang="scss">
::v-deep .userGridLayout .el-table__expanded-cell{
  padding: 15px !important;
}
::v-deep .userGridLayout .avue-crud .el-table {
  height: calc(100vh - 348px) !important;
  max-height: calc(100vh - 348px) !important;
}
::v-deep .el-tree {
  height: calc(100vh - 311px) !important;
}
</style>
