var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "center" },
    [
      _c(
        "el-container",
        [
          !_vm.isIndex && _vm.refresh
            ? _c("tree-layout", {
                ref: "treeLayout",
                staticStyle: { "margin-right": "5px" },
                attrs: {
                  treeLoading: _vm.treeLoading,
                  treeTitle: "组织架构",
                  "tree-options": _vm.treeOptions,
                },
                on: { "tree-node-click": _vm.treeNodeClick },
              })
            : _vm._e(),
          _c(
            "el-main",
            [
              _c("head-layout", {
                attrs: {
                  "head-title": this.$t(
                    "cip.plat.sync.user.title.indexHeadTitle"
                  ),
                  "head-btn-options": _vm.headBtnOptions,
                },
                on: {
                  "head-remove": _vm.handleDelete,
                  "head-add": _vm.headAdd,
                },
              }),
              _c("grid-head-layout", {
                ref: "gridHeadLayout",
                attrs: { "search-columns": _vm.searchColumns },
                on: {
                  "grid-head-search": _vm.gridHeadSearch,
                  "grid-head-empty": _vm.gridHeadEmpty,
                },
              }),
              _c("grid-layout", {
                ref: "gridLayOut",
                class: !_vm.isIndex ? "userGridLayout" : "",
                attrs: {
                  "head-title": this.$t(
                    "cip.plat.sync.user.title.indexHeadTitle"
                  ),
                  "table-options": _vm.option,
                  "table-data": _vm.data,
                  "table-loading": _vm.loading,
                  "data-total": _vm.page.total,
                  "grid-row-btn": _vm.gridRowBtn,
                  page: _vm.page,
                },
                on: {
                  "grid-row-detail-click": _vm.rowView,
                  "page-current-change": _vm.onLoad,
                  "page-size-change": _vm.onLoad,
                  "gird-handle-select-click": _vm.selectionChange,
                  "row-edit": _vm.rowEdit,
                  "row-del": _vm.rowDel,
                  "row-choice": _vm.rowChoice,
                },
                scopedSlots: _vm._u([
                  {
                    key: "expand",
                    fn: function ({ data }) {
                      return [
                        _c(
                          "el-table",
                          { attrs: { data: data.row.sysRefs, size: "mini" } },
                          [
                            _c("el-table-column", {
                              attrs: {
                                type: "index",
                                label: "序号",
                                align: "center",
                                width: "80",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                prop: "orgName",
                                label: "公司",
                                align: "center",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                prop: "deptName",
                                label: "部门",
                                align: "center",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                prop: "positionName",
                                label: "岗位",
                                align: "center",
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }